import React from 'react'

import { Link } from 'gatsby'


const Pitch = () => {
    return (
    <div className="pitch" data-aos="fade-right"
    data-aos-offset="300"
    data-aos-easing="ease-in-sine">
        <div className="page-width">
            <p>I operate a reliable, punctual, problem-solving web development studio. </p><p><strong>That’s the pitch.</strong> Sounds good? <Link to="/contact" className='hover'>Get in touch today!</Link></p>
        </div>
    </div>     
    )
}

export default Pitch
