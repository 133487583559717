import React from 'react'

import { Link } from 'gatsby'

import Layout from '../components/layout'

import { SEO } from "../components/seo"

import Pitch from '../components/pitch'

import transformers from "../images/transformers.jpg"
import ecomm from "../images/ecomm.jpg"
import hartt from "../images/hartt.jpg"
import cream from "../images/cream.jpg"


const home = () => {
  return (
    <div>
         <Layout>
              <div className="container page-width center">
                  <h2>What’s This All About?</h2>
                  <p class="intro-lead">North-NorthWest is a design and development studio of Igor Bujas, a web professional since 2003, specialized in UI design, CSS &amp; Shopify.</p>
                  <Link to="/about" className='btn mt20'>Find out more</Link>  
                  
                  <div className="featured-projects">

                   <div className='projects-group'>
                        <Link to="/transformers-magazine" className='animate__animated animate__zoomIn featured'><img src={transformers} alt="TM Magazine" /></Link>
                        <div className="projects-grid">
                            <Link to="/ecomm" className='animate__animated animate__zoomIn featured'><img src={ecomm} alt="Ecomm" /></Link>
                            <Link to="/hartt-media" className='animate__animated animate__zoomIn featured'><img src={hartt} alt="Hartt Media" /></Link>
                        </div>
                   </div>
                   <Link to="/amarte" className='animate__animated animate__zoomIn featured'><img src={cream} alt="Wonder Cream" /></Link>

                  </div>
              </div>       
              
              <Pitch />       
         </Layout>
    </div>
  )
  }

  export default home

  export const Head = () => (
    <SEO />
  )
  
  